<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row >
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Control de Pedidos</span>
	  		    <v-spacer></v-spacer>

	  		    <v-btn
              color="orange"
              dark
              outlined
              class="mr-2"
              @click="dialog = true"
              small
              tile
            >
              Agregar producto
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              tile
              small
            >
              <v-icon small>mdi-refresh</v-icon>
            </v-btn>

	  		  </v-card-title>
	  		  <v-card-text>
	  		  	<v-row justify="end">
              <v-col cols="12" md="3" lg="2" class="pb-0">
	  		  			<v-text-field label="Fecha Inicial" 
                  type="date" 
                  filled 
                  dense 
                  hide-details 
                  v-model="filtroFechaIni"
                ></v-text-field>
	  		  		</v-col>
              <v-col cols="12" md="3" lg="2" class="pb-0">
	  		  			<v-text-field label="Fecha Final" 
                  type="date" 
                  filled 
                  dense 
                  hide-details 
                  v-model="filtroFechaFin"
                ></v-text-field>
	  		  		</v-col>
              <v-spacer></v-spacer>
	  		  	</v-row>

            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12" md="3">
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>

	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="comidas"
							    class="elevation-0"
							    :search="search"
							    :mobile-breakpoint="100"
			            dense
                  item-class="clase_row"
							  >

                  <template v-slot:item.estatus="{ item }">
                    <v-chip color="orange" v-if="item.estatus == 1" small dark >Aceptado</v-chip>
                    <v-chip color="red"    v-if="item.estatus == 2" small dark >Rechazado</v-chip>
                    <v-chip color="green"  v-if="item.estatus == 3" small dark >Completado</v-chip>
                    <v-chip color="grey"   v-if="item.estatus == 0" small dark >Pendiente</v-chip>
                  </template>

                  <template v-slot:item.foto="{ item }">
                  	<v-img :src="url + item.foto" aspect-ratio="2" contain width="50"/>
                  </template>

							    <template v-slot:item.actions="{ item }">
							    	<v-btn 
                      tile
							    		color="primary" 
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>

							    	<v-btn 
                      tile
							    		color="error" 
							    		x-small
                      v-if="item.estatus == 0"
							    		@click="deleteItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small> mdi-delete</v-icon>
							    	</v-btn>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small

							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="400px"
      persistent
    >
      <v-card>
        <v-card-title>
        	{{formTitle}}
        	<v-img class="ml-2" src="@/assets/iconos/cesta.png" max-width="28"/>
        </v-card-title>

        <v-card-text>
          <v-row>
          	<v-col cols="12">
              <v-text-field
                v-model="editedItem.comida"
                label="* Comida"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="editedItem.costo"
                label="Costo"
                filled
                hide-details
                dense
                type="number"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="editedItem.descripcion"
                label="Descripcion"
                filled
                hide-details
                dense
                :rows="3"
                auto-grow
              ></v-textarea>
            </v-col>

          </v-row>
          <v-row>
            <v-card-text align="center" class="pa-0">
            	Foto
              <div v-if="!file">
                <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
                  <div class="dropZone-info" @drag="onChange">
                    <span class="fa fa-cloud-upload dropZone-title"></span>
                    <span class="dropZone-title blue--text"><b>Arrastre el archivo para cargar</b></span>
                    <div class="dropZone-upload-limit-info">
                      <div>Extensión: png, jpg, jpeg, svg</div>
                      <div>Tamaño máximo: 10 MB</div>
                    </div>
                  </div>
                  <input type="file" @change="onChange">
                </div>
              </div>

              <v-btn  v-else color="red" class="mb-2" dense @click="removeFile" fab small dark><v-icon>mdi-delete</v-icon></v-btn>
              <v-img :src="vistaPrevia.url" v-if="vistaPrevia" contain max-height="300"/>
            </v-card-text>
            
          </v-row>

          <v-row v-if="editedItem.foto">
            <v-card-text align="center" class="pa-0">
            	Foto
              <v-img :src="url + editedItem.foto" contain max-height="300"/>
            </v-card-text>
          </v-row>

        </v-card-text>

        <v-btn
          tile
          small
          @click="close"
          absolute
          right
          top
          icon
        >
          <v-icon small>mdi-close</v-icon>
        </v-btn>


        <v-card-actions>
          <v-btn
            color="blue darken-1"
            class=""
            large
            outlined
            block
            tile
            dark
            small
            @click="save()"
          >
            Guardar
            <v-img src="@/assets/iconos/recibo.png" max-width="32" class="ml-4"></v-img>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el pedido?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Ver evidencias -->
    <v-dialog v-model="dialogImagen" max-width="1000px">
      <v-card class="elevation-0">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn fab small text @click="dialogImagen = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-img
            :src=" url + imagen" 
            contain
            max-height="80vh"
          >
          </v-img>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    mixins: [ validarErrores, funcionesExcel ],

  	components:{
      Alerta,
      carga,
    },

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      
      editedIndex: -1,

      id_puesto:0,

      editedItem: {
        idcomidas: 0,
				comida: 0,
				descripcion: '',
				costo: 0,
				foto: 0,
				deleted:0,
				fecha_creacion: null,
      },

      defaultItem: {
        idcomidas: 0,
				comida: 0,
				descripcion: '',
				costo: 0,
				foto: 0,
				deleted:0,
				fecha_creacion: null,
      },

      // Filtros: 
      incio:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			fin  :(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      salones: [],
      headers: [
        { text: 'ID'       , value: 'idcomidas'   },
        { text: 'Comida'   , value: 'comida'      },
        { text: 'Descrip'  , value: 'descripcion' },
        { text: 'Costo'    , value: 'costo'       },
        { text: 'Foto'     , value: 'foto'        },
        { text: 'Actions'  , value: 'actions'     },
      ],

      pedidos: [],
      puestos: [],
      turnos:[],
      planteles:[],
      filtroEstatus: 0,
      filtroFechaIni: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      filtroFechaFin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),


      detalles:[],
      fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      comidas:[],

      actividad:'',
			realizada:0,
      dialogDetalles:false,

      proyecto:'',
      cliente:'',

      urlFotos: '',

      dialogPago: false,

      archivos:null,
      vistaPrevia: null,
      file: '',
      dragging: false,
      dialgImagen:false,
      url_servidor: '',
      fullscreen: false,
      pdf:null,
      dialogPDF: false,

      imagen: '',
			dialogImagen: false,

    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar comida' : 'Editar comida'
      },

      pagoTotal( ){

      	return this.comidas.filter( el => el.seleccionado ).map(item => item.costo).reduce((prev, curr) => prev + curr, 0)

      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

    },

    async created () {

    	this.url = axios.defaults.baseURL + "fotos_comidas/";

      await this.initialize()
      await this.getComidas()
    },

    methods: {
      initialize () {
        this.comidas = []
        this.$http.get('comidas.list').then(response=>{
          this.cargar = false

          this.comidas = response.data 
          // Traer los puestos
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.comidas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.comidas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.editedItem.deleted = 1

        this.$http.put('compras.elimninar/' + this.editedItem.idcomidas, this.editedItem).then(response=>{
        	this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async save () {
        // Cargamos al usuario que hizo el cambio
	      this.cargar = true

	      if( this.file ){
	        try{
	          this.editedItem.foto = await this.grabarImagen( )
	        }catch( error ){
	          return  this.validarError( error.response.data.message )
	        }
	      }


        if (this.editedIndex > -1) {
	        // Lo mandapos por el EP
          this.$http.put('comida.update/' + this.editedItem.idcomidas, this.editedItem).then(response=>{
          	this.validarSuccess( response.data.message )
          	this.initialize()
        		this.close()
	        }).catch( error =>{
	          this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })

        } else {

	        // Lo mandapos por el EP
          this.$http.post('comida.add', this.editedItem ).then(response=>{
            this.dialogPago = false
          	this.initialize()
        		this.close()
	        	this.cargar = false
	        }).catch( error =>{
          	this.validarError( error.response.data.message )
        	}).finally( () => { this.cargar = false })
        }
      },

      grabarImagen() {

        let formData = new FormData();
        formData.append("file", this.file);

        return axios.post("compras.comprobante", formData).then((response) => {
          this.vistaPrevia = null;
          this.file        = null;
          return Promise.resolve(response.data.nombre);
        }).catch((error) => {
          return Promise.reject(error);
        }).finally(() => {
          this.cargar = false;
        });
      },

      getBase64(file) {
        var me = this
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {

          let extension = file.name.split('.')

          me.vistaPrevia = {
            url: reader.result, 
            image_name: file.name,
            extension: extension[extension.length - 1]
          }
        };
      },
   
      onChange(e) {
        var files = e.target.files || e.dataTransfer.files;

        if (!files.length) {
          this.dragging = false;
          return;
        }
        
        this.createFile(files[0]);
      },

      createFile(file) {
        if (file.size > 40000000) {
          alert('please check file size no over 40 MB.')
          this.dragging = false;
          return;
        }
        
        let extension = file.name.split('.')
        
        this.file = file;
        this.extension = extension[extension.length - 1]
        this.getBase64(this.file)
        this.dragging = false;
      },

      removeFile() {
        this.vistaPrevia        = null
        this.archivos           = null
        this.file               = '';
      },

      verImagen( imagen ){
        this.imagen       = imagen
        this.dialogImagen = true
      },

    },
  }
</script>

<style scoped>
  .dropZone {
    width: 100%;
    height: 150px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px solid #D8D8D8;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  ..dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #D8D8D8;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 80%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D8D8D8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }

  /* Estilos del contenedor de cuadros */
  .cuadros-container {
    display: flex;
    justify-content: space-between; /* Distribuye los cuadros equitativamente */
    align-items: center; /* Alinea verticalmente los cuadros */
    flex-wrap: nowrap; /* Evita que los cuadros se envuelvan a la siguiente línea */
    padding: 10px;
  }

  /* Estilos de los cuadros */
  .cuadro {
    width: 18%; /* Ajusta el ancho de los cuadros según el espacio disponible */
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center;
  }

  .texto_contenedor{
    justify-content: center; /* Centrado horizontal */
    align-items: center; /* Centrado vertical */
    max-width: 100%;
    padding-left: 12%;
    padding-right: 12%;
  }
</style>